import { useCallback, useContext } from "react";
import { PrefixContext } from "providers/PrefixProvider";

export const usePathWithPrefix = () => {
  const { prefix } = useContext(PrefixContext);

  const addPrefix = useCallback((path: string) => `${prefix}${path}`, [prefix]);

  return {
    addPrefix,
    prefix,
  };
};
