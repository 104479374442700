import { Button, Input } from "@letsbit/milkylb";
import React from "react";
import { useIntl, FormattedMessage } from "react-intl";

import { messages } from "./messages";
import {
  AddressDetailsFormProps,
  AddressDetailsForm as AddrForm,
} from "./types";
import classNames from "classnames";
import { Controller, useForm } from "react-hook-form";
import { formMessages } from "utils/formMessages";

export const AddressDetailsForm: React.FC<AddressDetailsFormProps> = ({
  onSubmit,
  className,
  form,
}) => {
  const { handleSubmit, control } = useForm<AddrForm>({
    mode: "onBlur",
    defaultValues: form,
  });
  const { formatMessage } = useIntl();

  const postcode = form?.postcode;

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={classNames(
        className,
        "fadeInSimple relative flex flex-col items-center justify-center"
      )}
      data-cypress-id="address__details-form"
    >
      <div className="h-full w-full">
        <div className="flex flex-row justify-between gap-2">
          <Controller
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <div className="w-1/2">
                <Input
                  placeholder={formatMessage(messages.floorPlaceholder)}
                  value={value}
                  onIonInput={onChange}
                  error={!!error}
                  message={error?.message}
                  data-cypress-id="address__details-floor__input"
                />
              </div>
            )}
            name="floor"
          />
          <Controller
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <div className="w-1/2">
                <Input
                  placeholder={formatMessage(messages.apartmentPlaceholder)}
                  value={value}
                  onIonInput={onChange}
                  error={!!error}
                  message={error?.message}
                  data-cypress-id="address__details-apartment__input"
                />
              </div>
            )}
            name="apartment"
          />
        </div>
        <Controller
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <div>
              <Input
                placeholder={formatMessage(
                  messages.adtionalReferencesPlaceholder
                )}
                value={value}
                onIonInput={onChange}
                error={!!error}
                message={error?.message}
                data-cypress-id="address__details-aditional__info__input"
              />
            </div>
          )}
          name="additional_info"
        />
        {(!postcode || postcode === "") && (
          <Controller
            control={control}
            rules={{
              required: formatMessage(formMessages.requiredFieldError),
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <div>
                <Input
                  placeholder={formatMessage(messages.postcodePlaceholder)}
                  value={value}
                  onIonInput={onChange}
                  error={!!error}
                  message={error?.message}
                />
              </div>
            )}
            name="postcode"
          />
        )}
      </div>
      <Button
        type="submit"
        style={{ width: 197 }}
        className="mt-8 w-full self-end"
      >
        <FormattedMessage {...messages.continueButton} />
      </Button>
    </form>
  );
};
