import { lazy, Suspense, useContext, useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import LoadingAnimation from "components/LoadingAnimation";
import { UserContext } from "providers/UserProvider";
import { FadeInRouter } from "components/FadeInRouter";
import { NavBarPartner } from "./NavBarPartner";
import { LocationContext } from "providers/LocationProvider";
import classNames from "classnames";
import IdleTimer from "components/IdleTimer";
import { LoggedPartnerRoutesProps } from "./types";
import { usePathWithPrefix } from "hooks/utils/usePathWithPrefix";

// const DashboardRoutes = lazy(async () => ({
//   default: (await import("./DashboardRoutes")).DashboardRoutes,
// }));
const BalanceRoutes = lazy(async () => ({
  default: (await import("./BalanceRoutes")).BalanceRoutes,
}));
const MembersRoutes = lazy(async () => ({
  default: (await import("./MembersRoutes")).MembersRoutes,
}));
const WithdrawsRoutes = lazy(async () => ({
  default: (await import("./WithdrawsRoutes")).WithdrawsRoutes,
}));
const ProfileRoutes = lazy(async () => ({
  default: (await import("../shared-routes/ProfileRoutes")).ProfileRoutes,
}));
const SwapsRoutes = lazy(async () => ({
  default: (await import("./SwapsRoutes")).SwapsRoutes,
}));
const MarketPricesRoutes = lazy(async () => ({
  default: (await import("./MarketPricesRoutes")).MarketPricesRoutes,
}));
const ExchangeRoutes = lazy(async () => ({
  default: (await import("../shared-routes/ExchangeRoutes")).ExchangeRoutes,
}));
const DepositsRoutes = lazy(async () => ({
  default: (await import("./DepositsRoutes")).DepositsRoutes,
}));

export const LoggedPartnerRoutes: React.FC<LoggedPartnerRoutesProps> = () => {
  const { addPrefix, prefix } = usePathWithPrefix();
  const { user, loading, getUser } = useContext(UserContext);
  const { displayLocation } = useContext(LocationContext);

  useEffect(() => {
    if (user === undefined && !loading) {
      getUser();
    }
  }, [getUser, loading, user]);

  if (loading && user === undefined) {
    return <LoadingAnimation />;
  }

  // TODO: Hay que revisar fuertemente cuando ya sabemos que un usuario esta logueado y cuando no.
  // if (!loading && !user && !auth0isAuthenticated) {
  //   return <Navigate to="/login" replace />;
  // }

  return (
    <div className="flex h-full">
      <NavBarPartner />
      <IdleTimer />
      <FadeInRouter
        className={classNames(
          "flex min-w-0 grow flex-col",
          !displayLocation.pathname.startsWith(addPrefix("/dashboard")) &&
            "pb-10 pl-5 pr-10 pt-10",
        )}
        prefix={prefix}
      >
        <Suspense fallback={<LoadingAnimation />}>
          <Routes>
            <Route
              path="/dashboard/*"
              element={<Navigate to={addPrefix("/profile")} replace={true} />}
            />
            <Route path="/balance/*" element={<BalanceRoutes />} />
            <Route path="/members/*" element={<MembersRoutes />} />
            <Route path="/withdraws/*" element={<WithdrawsRoutes />} />
            <Route path="/profile/*" element={<ProfileRoutes />} />
            <Route path="/swaps/*" element={<SwapsRoutes />} />
            <Route path="/deposits/*" element={<DepositsRoutes />} />
            <Route path="/market-prices/*" element={<MarketPricesRoutes />} />
            <Route path="/exchange/*" element={<ExchangeRoutes />} />
            <Route
              path="*"
              element={<Navigate to={addPrefix("/dashboard")} replace={true} />}
            />
            <Route path="/exchange/*" element={<ExchangeRoutes />} />
          </Routes>
        </Suspense>
      </FadeInRouter>
    </div>
  );
};
