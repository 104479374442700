import React, { useEffect, PropsWithChildren, useMemo } from "react";
import { useLocation } from "react-router";

import { Modal } from "components/Modal";
import { usePrecogsWs } from "hooks/user/usePrecogsWs";
import PrecogsFormSlider from "components/PrecogsFormSlider";
import { FlagsHandlerProps } from "./types";
import { usePathWithPrefix } from "hooks/utils/usePathWithPrefix";

export const FlagsHandler: React.FC<PropsWithChildren<FlagsHandlerProps>> = ({
  children,
}) => {
  const { flags, showFlags, setShowFlags, setFlags } = usePrecogsWs();
  const location = useLocation();
  const { addPrefix } = usePathWithPrefix();

  const ENABLED_PATHS = useMemo(
    () => ["/dashboard", "/balance", "/profile", "/history"].map(addPrefix),
    [addPrefix],
  );

  useEffect(() => {
    let timeout = 0;
    if (
      flags.length > 0 &&
      ENABLED_PATHS.find((path) => location.pathname.includes(path))
    ) {
      timeout = window.setTimeout(() => setShowFlags(true), 1000);
    }

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [flags, location, setFlags, setShowFlags, addPrefix]);

  return (
    <>
      <Modal isOpen={showFlags} withArrow={false} className="bg-[#fff]">
        {showFlags && flags[0] && (
          <PrecogsFormSlider
            flag={flags[0]}
            onExit={() => {
              setShowFlags(false);

              /* This clean up the flags because you already use it*/
              setFlags([]);
            }}
          />
        )}
      </Modal>
      {children}
    </>
  );
};

export default FlagsHandler;
