import React, { ReactNode } from "react";

interface PrefixContext {
  prefix: string;
}

export const USER_RETAIL = "/logged";
export const USER_PARTNER = "/logged-partner";

export const PrefixContext = React.createContext<PrefixContext>({
  prefix: USER_RETAIL,
});

export type Props = {
  children: ReactNode;
  prefix: string;
};

const PrefixProvider = (props: Props) => {
  const { children, prefix } = props;

  return (
    <PrefixContext.Provider
      value={{
        prefix,
      }}
    >
      {children}
    </PrefixContext.Provider>
  );
};

export default PrefixProvider;
