import { defineMessages } from "react-intl";

export const scope = "pages.OnBoardingLocal.CompanyNationalIdPage";

export const messages = defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: "Datos de la empresa",
  },
});
