import { Button, Error, RadioGroup, Radio } from "@letsbit/milkylb";
import React from "react";
import { useIntl, FormattedMessage } from "react-intl";

import { messages } from "./messages";
import { FundsOriginFormProps } from "./types";
import classNames from "classnames";
import { Controller, useForm } from "react-hook-form";
import { formMessages } from "utils/formMessages";
import { ReactComponent as InfoIcon } from "assets/images/icons/info.svg";

export const FundsOriginForm: React.FC<FundsOriginFormProps> = ({
  onSubmit,
  className,
  form,
}) => {
  const { handleSubmit, control } = useForm<{
    source_of_funds: "own" | "other";
  }>({
    mode: "onBlur",
    defaultValues: form && {
      source_of_funds:
        form.source_of_funds === undefined
          ? undefined
          : form.source_of_funds
          ? "own"
          : "other",
    },
  });
  const { formatMessage } = useIntl();

  return (
    <form
      onSubmit={handleSubmit((data) =>
        onSubmit({
          source_of_funds: data.source_of_funds === "own",
        })
      )}
      className={classNames(
        className,
        "fadeInSimple relative flex flex-col items-center justify-center"
      )}
      data-cypress-id="funds__origin-form"
    >
      <div className="h-full w-full">
        <Controller
          control={control}
          rules={{
            required: formatMessage(formMessages.requiredFieldError),
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <div>
              <label className="grid-flow-row text-lg font-normal text-[--color-neutral-0]">
                <FormattedMessage {...messages.foundOriginsLabel} />
              </label>
              <RadioGroup value={value} onIonChange={onChange}>
                <div className="mt-4 flex w-full flex-row gap-x-10">
                  <div className="flex flex-row gap-x-2">
                    <Radio key="own" value="own" labelPlacement="end">
                      <label className="font-['Tomato_Grotesk'] text-[--milkylb-color-neutral-800]">
                        <FormattedMessage {...messages.ownFundsLabel} />
                      </label>
                    </Radio>
                  </div>
                  <div className="flex flex-row gap-x-2">
                    <Radio
                      key="other"
                      value="other"
                      data-cypress-id="funds__origin-other__radio"
                      labelPlacement="end"
                    >
                      <label className="font-['Tomato_Grotesk'] text-[--milkylb-color-neutral-800]">
                        <FormattedMessage {...messages.thirdsFundsLabel} />
                      </label>
                    </Radio>
                  </div>
                </div>
                <Error
                  error={!!error}
                  message={error?.message}
                  className="mt-2"
                />
              </RadioGroup>
            </div>
          )}
          name="source_of_funds"
        />
        <div
          className={
            "flex items-center text-xs font-semibold text-[--milkylb-color-neutral-400]"
          }
        >
          <InfoIcon className="shrink-0" />{" "}
          <p className="ml-2">
            <FormattedMessage {...messages.infoLabel} />
          </p>
        </div>
      </div>
      <Button
        type="submit"
        style={{ width: 197 }}
        className="mt-8 w-full self-end"
      >
        <FormattedMessage {...messages.continueButton} />
      </Button>
    </form>
  );
};
