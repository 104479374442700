import classNames from "classnames";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { FormattedMessage } from "react-intl";
import { UserContext } from "providers/UserProvider";
// import { useIntercom } from "react-use-intercom";

import { NavBarPartnerButtonProps } from "./types";

import { ReactComponent as ChatIcon } from "assets/images/icons/navbar/chat.svg";
import { ReactComponent as HelpIcon } from "assets/images/icons/navbar/help.svg";
import { ReactComponent as LogoutIcon } from "assets/images/icons/navbar/logout.svg";
// import { ReactComponent as HomeIcon } from "assets/images/icons/navbar/home.svg";
import { ReactComponent as ProfileIcon } from "assets/images/icons/navbar/profile.svg";
import { ReactComponent as UsersIcon } from "assets/images/icons/navbar/users.svg";
import { ReactComponent as TransferIcon } from "assets/images/icons/navbar/transfer.svg";
import { ReactComponent as LogoIcon } from "assets/images/icons/navbar/logo.svg";
import { ReactComponent as WalletIcon } from "assets/images/icons/navbar/wallet.svg";
import { ReactComponent as HistoryIcon } from "assets/images/icons/navbar/history.svg";
import { ReactComponent as SwapIcon } from "assets/images/icons/navbar/swap.svg";
import { ReactComponent as DepositIcon } from "assets/images/icons/navbar/deposit.svg";
import { messages } from "./messages";
import { LocationContext } from "providers/LocationProvider";

const NavBarButton: React.FC<NavBarPartnerButtonProps> = ({
  icon,
  children,
  active,
  color = "--milkylb-color-secondary-100",
  ...props
}) => {
  return (
    <button
      className={classNames(
        `flex w-full items-center rounded-lg px-2 transition-all`,
        !active && "hover:bg-[--color-navbar-hover]",
      )}
      style={{
        color: `var(${color})`,
      }}
      {...props}
    >
      <span
        className={classNames(
          "mr-4 p-2",
          active && "rounded-full bg-[--color-alternative-accent]",
        )}
      >
        {icon}
      </span>
      {children}
    </button>
  );
};

export const NavBarPartner: React.FC = () => {
  const { logout } = useContext(UserContext);
  // const { show } = useIntercom();
  const { location } = useContext(LocationContext);

  return (
    <nav
      className={classNames(
        "fadeIn w-[calc((100vw - 60px) / 12)] flex min-w-[237px] flex-col justify-between bg-[--milkylb-color-secondary-800] px-6 py-8",
        !location.pathname.startsWith("/logged-partner") && "fadeOut",
      )}
    >
      <div className="flex flex-col gap-1">
        <LogoIcon className="mb-8 ml-2 flex h-8 w-8 items-center justify-center" />
        {/* <Link to="/logged-partner/dashboard">
          <NavBarButton
            icon={<HomeIcon className="h-5 w-5" />}
            active={location.pathname.startsWith("/logged-partner/dashboard")}
          >
            <FormattedMessage {...messages.homeLabel} />
          </NavBarButton>
        </Link> */}
        <Link to="/logged-partner/balance">
          <NavBarButton
            active={location.pathname.startsWith("/logged-partner/balance")}
            icon={<WalletIcon className="h-5 w-5" />}
          >
            <FormattedMessage {...messages.balanceLabel} />
          </NavBarButton>
        </Link>
        <Link to="/logged-partner/deposits">
          <NavBarButton
            active={location.pathname.startsWith("/logged-partner/deposits")}
            icon={<DepositIcon className="h-5 w-5" />}
          >
            <FormattedMessage {...messages.depositsLabel} />
          </NavBarButton>
        </Link>
        <Link to="/logged-partner/members">
          <NavBarButton
            active={location.pathname.startsWith("/logged-partner/members")}
            icon={<UsersIcon className="h-5 w-5" />}
          >
            <FormattedMessage {...messages.usersLabel} />
          </NavBarButton>
        </Link>
        <Link to="/logged-partner/withdraws">
          <NavBarButton
            active={location.pathname.startsWith("/logged-partner/withdraws")}
            icon={<TransferIcon className="h-5 w-5" />}
          >
            <FormattedMessage {...messages.withdrawsLabel} />
          </NavBarButton>
        </Link>
        {/* TODO: We should remove this and do it properly */}
        <Link to="/logged-partner/swaps">
          <NavBarButton
            active={location.pathname.startsWith("/logged-partner/swaps")}
            icon={<HistoryIcon className="h-5 w-5" />}
          >
            <FormattedMessage {...messages.swapsHistoryLabel} />
          </NavBarButton>
        </Link>
        <Link to="/logged-partner/exchange">
          <NavBarButton
            active={location.pathname.startsWith("/logged-partner/exchange")}
            icon={<SwapIcon className="h-5 w-5" />}
          >
            <FormattedMessage {...messages.swapLabel} />
          </NavBarButton>
        </Link>
        <Link to="/logged-partner/profile">
          <NavBarButton
            active={location.pathname.startsWith("/logged-partner/profile")}
            icon={<ProfileIcon className="h-5 w-5" />}
          >
            <FormattedMessage {...messages.profileLabel} />
          </NavBarButton>
        </Link>
      </div>
      <div className="flex flex-col gap-1">
        <a href="https://help.letsbit.io/" target="_blank">
          <NavBarButton
            icon={<HelpIcon className="h-5 w-5" />}
            color="--milkylb-color-secondary-200"
          >
            <FormattedMessage {...messages.helpCenterLabel} />
          </NavBarButton>
        </a>
        <a
          href="https://letsbit.atlassian.net/servicedesk/customer/portal/3"
          target="_blank"
        >
          <NavBarButton
            icon={<ChatIcon className="h-5 w-5" />}
            color="--milkylb-color-secondary-200"
          >
            <FormattedMessage {...messages.contactLabel} />
          </NavBarButton>
        </a>

        <NavBarButton
          icon={<LogoutIcon className="h-5 w-5" />}
          onClick={() => logout()}
          color="--milkylb-color-red-error"
        >
          <FormattedMessage {...messages.logOutLabel} />
        </NavBarButton>
      </div>
    </nav>
  );
};
