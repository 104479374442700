import { Button } from "@letsbit/milkylb";
import { DividerWithCarousel } from "components/DividerWithCarousel";
import { LogOutButton } from "components/LogOutButton";
import React from "react";
import { FormattedMessage } from "react-intl";
import { messages } from "./messages";
import { useIntercom } from "react-use-intercom";
import { SolutionContent } from "../../components/SolutionContent";

export const AlreadyRegistredCustomerPage: React.FC = () => {
  const { show } = useIntercom();

  return (
    <DividerWithCarousel carouselContent={[<SolutionContent />]} noDots noSwipe>
      <div className="flex h-full flex-col items-end justify-between px-[20%] pb-[20%] pt-[40%]">
        <div>
          <LogOutButton className="absolute right-[40px] top-[24px]" />
          <h1 className="mb-8 w-full font-['Tomato_Grotesk'] text-4xl font-bold text-[--milkylb-color-neutral-600]">
            <FormattedMessage
              {...messages.errorTitle}
              values={{
                br: <br />,
              }}
            />
          </h1>
          <p className="mb-5 text-base">
            <FormattedMessage {...messages.errorSusbtitle1} />
          </p>
          <p className="text-base">
            <FormattedMessage {...messages.errorSusbtitle2} />
          </p>
        </div>
        <Button onClick={() => show()}>
          <FormattedMessage {...messages.chatButton} />
        </Button>
      </div>
    </DividerWithCarousel>
  );
};
