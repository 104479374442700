import { defineMessages } from "react-intl";

export const scope = "components.NavBarPartner";

export const messages = defineMessages({
  withdrawLabel: {
    id: `${scope}.withdrawLabel`,
    defaultMessage: "Enviar",
  },
  swapLabel: {
    id: `${scope}.swapLabel`,
    defaultMessage: "Convertir",
  },
  swapsHistoryLabel: {
    id: `${scope}.swapsHistoryLabel`,
    defaultMessage: "Swaps",
  },
  depositLabel: {
    id: `${scope}.depositLabel`,
    defaultMessage: "Recibir",
  },
  homeLabel: {
    id: `${scope}.homeLabel`,
    defaultMessage: "Inicio",
  },
  balanceLabel: {
    id: `${scope}.balanceLabel`,
    defaultMessage: "Balance",
  },
  multipleLabel: {
    id: `${scope}.multipleLabel`,
    defaultMessage: "Envío múltiple",
  },
  usersLabel: {
    id: `${scope}.membersLabel`,
    defaultMessage: "Usuarios",
  },
  withdrawsLabel: {
    id: `${scope}.withdrawsLabel`,
    defaultMessage: "Transferencias",
  },
  profileLabel: {
    id: `${scope}.profileLabel`,
    defaultMessage: "Perfil",
  },
  helpCenterLabel: {
    id: `${scope}.helpCenterLabel`,
    defaultMessage: "Centro de ayuda",
  },
  contactLabel: {
    id: `${scope}.contactLabel`,
    defaultMessage: "Atención al cliente",
  },
  logOutLabel: {
    id: `${scope}.logOutLabel`,
    defaultMessage: "Cerrar sesión",
  },
  historyLabel: {
    id: `${scope}.historyLabel`,
    defaultMessage: "Historiales",
  },
  depositsLabel: {
    id: `${scope}.depositsLabel`,
    defaultMessage: "Depósitos",
  },
});
