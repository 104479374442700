import { setupIonicReact } from "@letsbit/milkylb";
import { lazy, Suspense, useContext, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import ReactPixel from "react-facebook-pixel";
import Adjust from "@adjustcom/adjust-web-sdk";

import LoadingAnimation from "./components/LoadingAnimation";
import { LoggedRoutes } from "./pages/LoggedRoutes";
import { LoggedPartnerRoutes } from "./pages/LoggedPartnerRoutes";
import { LocationContext } from "./providers/LocationProvider";
import { FadeInRouter } from "components/FadeInRouter";

import dayjs from "dayjs";
import "dayjs/locale/es"; // load on demand
import { OnBoardingSelectAccountTypePage } from "./pages/LoggedRoutes/OnBoardingRoutes/OnBoardingSelectAccountTypePage";
import PrefixProvider from "providers/PrefixProvider";

setupIonicReact();
dayjs.locale("es");

const AccountsRoutes = lazy(async () => ({
  default: (await import("./pages/PublicRoutes/AccountsRoutes")).AccountsRoutes,
}));
const LogInRoutes = lazy(async () => ({
  default: (await import("./pages/PublicRoutes/LogInRoutes")).LogInRoutes,
}));
const Auth0Routes = lazy(async () => ({
  default: (await import("./pages/PublicRoutes/Auth0Routes")).Auth0Routes,
}));
const SignUpRoutes = lazy(async () => ({
  default: (await import("./pages/PublicRoutes/SignUpRoutes")).SignUpRoutes,
}));
const DefaultRoutes = lazy(async () => ({
  default: (await import("./pages/PublicRoutes/DefaultRoutes")).DefaultRoutes,
}));
const SessionExpiredRoute = lazy(async () => ({
  default: (await import("./pages/PublicRoutes/LogInRoutes/SessionExpired"))
    .SessionExpired,
}));
const ErrorRoute = lazy(async () => ({
  default: (await import("./pages/PublicRoutes/ErrorRoutes")).ErrorRoutes,
}));

if (typeof window !== "undefined") {
  ReactPixel.init(window.env.pixelId, undefined, {
    debug: false,
    autoConfig: true,
  });
  Adjust.initSdk({
    appToken: window.env.adjust.appToken,
    environment: window.env.adjust.environment,
  });
}

function App() {
  const { displayLocation } = useContext(LocationContext);

  useEffect(() => {
    ReactPixel.pageView();
  }, [displayLocation.pathname]);

  return (
    <Suspense fallback={<LoadingAnimation />}>
      <Routes location={displayLocation}>
        <Route
          path="/accounts/*"
          element={
            <FadeInRouter
              className="flex h-full w-full items-center justify-center"
              prefix="/accounts"
            >
              <AccountsRoutes />
            </FadeInRouter>
          }
        />
        <Route
          path="/signup/*"
          element={
            <FadeInRouter
              className="flex h-full w-full items-center justify-center"
              prefix="/signup"
            >
              <SignUpRoutes />
            </FadeInRouter>
          }
        />
        <Route path="/login/*" element={<LogInRoutes />} />
        <Route
          path="/onboarding/select-account-type"
          element={<OnBoardingSelectAccountTypePage />}
        />
        <Route path="/auth0/*" element={<Auth0Routes />} />
        <Route
          path="/logged/*"
          element={
            <PrefixProvider prefix="/logged">
              <LoggedRoutes />
            </PrefixProvider>
          }
        />
        <Route
          path="/logged-partner/*"
          element={
            <PrefixProvider prefix="/logged-partner">
              <LoggedPartnerRoutes />
            </PrefixProvider>
          }
        />
        <Route path="/session-expired" element={<SessionExpiredRoute />} />
        <Route path="/error" element={<ErrorRoute />} />

        <Route path="*" element={<DefaultRoutes />} />
      </Routes>
    </Suspense>
  );
}

export default App;
