import React, { useContext, useRef } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, OptionSelector } from "@letsbit/milkylb";
import { Controller, useForm } from "react-hook-form";
import { PERSON_TYPES } from "@letsbit/malcolmlb";
import { useNavigate } from "react-router-dom";
import { CarouselRef } from "antd/es/carousel";

import { UserContext } from "providers/UserProvider";
import { useNotification } from "hooks/utils/useNotification";
import { NotificationStatus } from "components/Notification/types";
import { DividerWithCarousel } from "components/DividerWithCarousel";
import LogInEnterprise from "assets/images/login_enterprise.svg";

import { messages } from "./messages";
import { usePathWithPrefix } from "hooks/utils/usePathWithPrefix";

export const OnBoardingSelectAccountTypePage: React.FC = () => {
  const carouselRef = useRef<CarouselRef | null>(null);
  const intl = useIntl();
  const { addPrefix } = usePathWithPrefix();
  const { showNotification } = useNotification();
  const navigate = useNavigate();
  const { updateUserData } = useContext(UserContext);
  const { control, handleSubmit } = useForm<{ person_type: PERSON_TYPES }>();
  const blockImage = [
    {
      img: <img className="h-[400px] w-[400px]" src={LogInEnterprise} />,
    },
  ];

  const onSubmit = async (values: { person_type: PERSON_TYPES }) => {
    try {
      await updateUserData(values);
      navigate(addPrefix("/onboarding"));
    } catch (err) {
      showNotification({
        title: intl.formatMessage(messages.notificationTitle),
        message: intl.formatMessage(messages.notificationMessage),
        status: NotificationStatus.ERROR,
      });
    }
  };

  return (
    <DividerWithCarousel
      carouselContent={blockImage.map((item, idx) => (
        <div
          key={idx}
          className="flex h-full w-full items-center justify-center p-8"
        >
          <div className="flex w-full max-w-[500px] flex-col justify-center">
            {item.img}
            <h2 className="pb-4 text-center font-['Tomato_Grotesk'] text-3xl font-bold text-[--milkylb-color-neutral-0]">
              <FormattedMessage
                {...messages.carouselTitle}
                values={{ number: 2 }}
              />
            </h2>
            <h4 className="text-center text-base font-normal text-[--milkylb-color-neutral-0]">
              <FormattedMessage
                {...messages.carouselSubtitle}
                values={{
                  br: <br />,
                }}
              />
            </h4>
          </div>
        </div>
      ))}
      forwardRef={carouselRef}
      noDots
      noSwipe
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex w-full items-center justify-center"
      >
        <div className="flex max-w-[420px] flex-col gap-y-8">
          <div className="pb-4 text-left font-['Tomato_Grotesk'] text-4xl font-bold text-[--milkylb-color-neutral-600]">
            <FormattedMessage {...messages.title} values={{ br: <br /> }} />
          </div>
          <Controller
            control={control}
            rules={{
              required: intl.formatMessage(messages.errorRequired),
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <div className="w-full cursor-pointer">
                <OptionSelector
                  option1={{
                    value: PERSON_TYPES.NATURAL,
                    label: intl.formatMessage(messages.naturalPerson),
                  }}
                  option2={{
                    value: PERSON_TYPES.JURIDICAL,
                    label: intl.formatMessage(messages.juridicalPerson),
                  }}
                  selected={value}
                  setSelected={(e) => {
                    onChange(e);
                  }}
                  error={!!error}
                  errorMsg={intl.formatMessage(messages.errorRequired)}
                />
              </div>
            )}
            name="person_type"
          />
          <Button type="submit">
            <FormattedMessage {...messages.confirm} />
          </Button>
        </div>
      </form>
    </DividerWithCarousel>
  );
};
