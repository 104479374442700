import React, { useRef } from "react";
import { ModalProps } from "./types";
import classNames from "classnames";
import { IonModal } from "@ionic/react";
import { ReactComponent as SimpleArrowIcon } from "assets/images/icons/simple-arrow.svg";
import "./styles.scss";

export const Modal: React.FC<ModalProps> = ({
  className,
  children,
  classNameModalWrapper,
  withArrow = true,
  ...props
}) => {
  const modal = useRef<HTMLIonModalElement | null>(null);

  return (
    <IonModal
      mode="ios"
      ref={modal}
      className={classNameModalWrapper || "letsbit-modal"}
      {...props}
    >
      <div className={classNames("h-full px-6 py-8", className)}>
        {withArrow && (
          <SimpleArrowIcon
            onClick={() => {
              modal.current?.dismiss();
            }}
            className="absolute cursor-pointer"
          />
        )}
        {children}
      </div>
    </IonModal>
  );
};
