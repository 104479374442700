import React, { useContext } from "react";
import { FormattedMessage } from "react-intl";
import { CompanyNationalIdPageProps } from "../types";
import { UserContext } from "providers/UserProvider";

import { JuridicalCustomerForm } from "../../components/forms/JuridicalCustomerForm";
import { messages } from "./messages";
import { LogOutButton } from "components/LogOutButton";

export const CompanyNationalIdPage: React.FC<
  CompanyNationalIdPageProps
> = ({ onSubmit }) => {
  const { user } = useContext(UserContext);
  const country = user?.data.country_residence as string;

  return (
    <div className="fadeInSimple relative flex h-full w-full flex-col items-center justify-center">
      <LogOutButton className="absolute right-[40px] top-[24px]" />
      <div className="h-full flex flex-col py-[5%]">
        <h1 className="mb-8 w-full font-['Tomato_Grotesk'] text-4xl font-bold text-[--milkylb-color-neutral-600]">
          <FormattedMessage {...messages.title} />
        </h1>
        <JuridicalCustomerForm className="grow w-[448px]" country={country} onSubmit={onSubmit} />
      </div>
    </div>
  );
};
