import React, { createContext, useMemo } from "react";
import axios, {
  AxiosRequestConfig,
  AxiosResponse,
  AxiosStatic,
  AxiosError,
} from "axios";
import {
  EVENT_TRACKER_EVENT_NAMES,
  Fetcher,
  MemberBoundary,
  PublicBoundary,
  PartnerBoundary,
} from "@letsbit/malcolmlb";
import { ServiceEnv } from "@letsbit/malcolmlb/lib/interfaces/services.types";
import { LogInAuthentication } from "@letsbit/malcolmlb/lib/LogInAuthentication";

import { createVoidContext } from "utils/voidContext";
import { useEventTracking } from "hooks/event_tracking/useEventTracking";
import EventTracker from "hooks/event_tracking/eventTracker";
import { useNavigate } from "react-router-dom";
import { errorParser } from "utils/defaultError";

interface ApiContextType {
  memberInstance: MemberBoundary;
  publicInstance: PublicBoundary;
  partnerInstance: PartnerBoundary;
}

export const ApiContext = createContext<ApiContextType>(
  createVoidContext("api-context"),
);

type ErrorMiddleware = (error: AxiosError) => Promise<void>;

class PostHogFetcher implements Fetcher {
  axios: AxiosStatic;
  tracker: EventTracker;
  errorMiddlewares: Array<ErrorMiddleware> = [];

  constructor(tracker: EventTracker) {
    this.tracker = tracker;
    this.axios = axios;
  }

  addErrorMiddleware(errorMiddleware: ErrorMiddleware): number {
    return this.errorMiddlewares.push(errorMiddleware) - 1;
  }

  removeErrorMiddleware(index: number) {
    this.errorMiddlewares = this.errorMiddlewares.filter((_, i) => i === index);
  }

  async request<T>(config: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    try {
      const response = await this.axios(config);

      return response;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        await Promise.all(
          this.errorMiddlewares.map((f) => f(error as AxiosError)),
        );

        if (error.response?.status !== 401 && error.response?.status !== 403) {
          this.tracker.capture(EVENT_TRACKER_EVENT_NAMES.API_ERROR, {
            request: config,
            response: error.response,
          });
        }
      }

      throw error;
    }
  }
}

const env = window.env.apiEnv || "test";
const malcolmlbUrl = window.env.malcolmlbUrl;
const config = {
  env: env as ServiceEnv,
  version: "v1",
  url: malcolmlbUrl ? malcolmlbUrl : undefined,
};

export const ApiProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const eventTracker = useEventTracking();
  const navigate = useNavigate();

  const fetcher = useMemo(
    () => new PostHogFetcher(eventTracker),
    [eventTracker],
  );

  const authenticationApp = useMemo(
    () =>
      new LogInAuthentication(
        {
          ...config,
          withCredentials: true,
        },
        fetcher,
      ),
    [fetcher],
  );

  fetcher.addErrorMiddleware(async (error) => {
    if (error.response?.status === 401) {
      switch (errorParser(error)) {
        case "authz.pending_authorization":
          break;
        case "authz.rejected_session":
          navigate("/login/unauthorized");
          break;
        default:
          navigate("/login");
          break;
      }
    }
  });

  const memberInstance = useMemo(
    () => new MemberBoundary(authenticationApp),
    [authenticationApp],
  );

  const partnerInstance = useMemo(
    () => new PartnerBoundary(authenticationApp),
    [authenticationApp],
  );

  const publicInstance = useMemo(
    () =>
      new PublicBoundary({
        ...config,
      }),
    [],
  );

  return (
    <ApiContext.Provider
      value={{
        partnerInstance,
        publicInstance,
        memberInstance,
      }}
    >
      {children}
    </ApiContext.Provider>
  );
};
