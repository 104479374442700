import { Button } from "@letsbit/milkylb";
import classNames from "classnames";
import { useIntercom } from "react-use-intercom";

import guyWithLogo from "assets/images/info_screen.svg";
import { ReactComponent as ChatIcon } from "assets/images/icons/navbar/chat.svg";
import { InfoScreenProps } from "./types";

export const InfoScreen: React.FC<InfoScreenProps> = ({
  className,
  headerClassName,
  infoClassName,
  pictureClassName,
  picture,
  noPicture = false,
  info,
  filledButton,
  clearButton,
  outlineButton,
  header,
  footer,
  chatButton = false,
}) => {
  const { show } = useIntercom();
  return (
    <div className="h-full w-full">
      <div
        className={classNames(
          className,
          "relative flex h-full flex-col justify-between",
        )}
      >
        <div
          className={classNames(
            "-m-1 flex flex-shrink flex-col overflow-hidden p-1 text-center",
            headerClassName,
          )}
        >
          {chatButton && (
            <ChatIcon onClick={show} className="absolute right-0" />
          )}
          {header && <div style={{ flexShrink: 0 }}>{header}</div>}
        </div>
        <div
          className={classNames(
            "-m-1 flex flex-shrink flex-col overflow-hidden px-6 text-center",
            headerClassName,
          )}
        >
          {!noPicture && (
            <div className="relative mb-8 flex w-full flex-shrink justify-center overflow-hidden">
              <img
                className={classNames(pictureClassName, "object-contain")}
                src={picture ? picture : guyWithLogo}
                alt="image_holder"
              />
            </div>
          )}
          <div className={classNames("flex-shrink-0", infoClassName)}>
            {info}
          </div>
        </div>
        <div className="mt-10 px-6">
          {footer && (
            <div
              style={
                (filledButton || outlineButton || clearButton) && {
                  marginBottom: 22,
                }
              }
            >
              {footer}
            </div>
          )}
          {filledButton && (
            <Button
              style={
                clearButton || outlineButton
                  ? { marginBottom: "8px" }
                  : undefined
              }
              fill="solid"
              className="w-full"
              color={filledButton.color}
              onClick={filledButton.onSubmit}
              isLoading={filledButton.isLoading}
              form={filledButton.form}
              type={filledButton.type}
              data-cypress-id={filledButton.dataCypressId}
            >
              {filledButton.text}
            </Button>
          )}
          {outlineButton && (
            <Button
              fill="outline"
              className="w-full"
              onClick={outlineButton.onSubmit}
              color={outlineButton.color}
              isLoading={outlineButton.isLoading}
            >
              {outlineButton.text}
            </Button>
          )}
          {clearButton && (
            <Button
              fill="clear"
              className="w-full"
              onClick={clearButton.onSubmit}
              color={clearButton.color}
              isLoading={clearButton.isLoading}
              form={clearButton.form}
              type={clearButton.type}
            >
              {clearButton.text}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
export default InfoScreen;
