import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { InformationRequestsReplyBody } from "@letsbit/malcolmlb";
import LoadingAnimation from "components/LoadingAnimation";
import { InformationRequestsRoutesProps } from "./types";
import { useInformationRequests } from "hooks/compliance/useInformationRequests";
import { InformationRequestsPage } from "../../InformationRequestsRoutes/InformationRequestsPage";
import { InformationRequestUploadPage } from "components/InformationRequestUploadPage";
import { usePathWithPrefix } from "hooks/utils/usePathWithPrefix";

export const InformationRequestsRoutes: React.FC<
  InformationRequestsRoutesProps
> = ({ informationRequests, juridicalPersonData, reloadSteps }) => {
  const navigate = useNavigate();
  const { addPrefix } = usePathWithPrefix();

  const { submitInformationRequest, notifyInformationRequest, loading } =
    useInformationRequests();

  const onSubmitNotifyAdmin = async () => {
    await notifyInformationRequest();
    reloadSteps();
  };

  const onSubmitUploadFile = async (form: InformationRequestsReplyBody) => {
    await submitInformationRequest(form);
    navigate("./");
    reloadSteps();
  };

  return (
    <>
      {!informationRequests && <LoadingAnimation />}
      <Routes>
        <Route
          path="/"
          element={
            <InformationRequestsPage
              informationRequests={informationRequests}
              onSubmit={onSubmitNotifyAdmin}
            />
          }
        />
        <Route
          path="/upload/:id"
          element={
            <InformationRequestUploadPage
              onSubmit={onSubmitUploadFile}
              informationRequests={informationRequests}
              directorName={juridicalPersonData?.director_name || ""}
              directorMemberEmail={
                juridicalPersonData?.director_member_email || ""
              }
              loading={loading}
            />
          }
        />
        <Route
          path="*"
          element={
            <Navigate
              to={addPrefix("/onboarding/local/information-requests")}
              replace={true}
            />
          }
        />
      </Routes>
    </>
  );
};
