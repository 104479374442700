import { useNavigate } from "react-router-dom";
import { InformationRequestsPageProps } from "./types";
import { LogOutButton } from "components/LogOutButton";
import { FormattedMessage, useIntl } from "react-intl";
import { messages } from "./messages";
import { INFORMATION_REQUEST_STATUS } from "@letsbit/malcolmlb";
import { InformationRequestCard } from "../../../../../components/InformationRequestDisplay";
import { ReactComponent as UploadIcon } from "assets/images/icons/upload-simple.svg";
import { AlertCard, Button } from "@letsbit/milkylb";
import { useEffect } from "react";
import { HelpBubble } from "components/HelpBubble";

export const InformationRequestsPage: React.FC<
  InformationRequestsPageProps
> = ({ informationRequests, onSubmit }) => {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();

  const hasUpdates = informationRequests?.find(
    (ir) => ir.status === INFORMATION_REQUEST_STATUS.NOTIFY_ADMIN,
  );
  const awaitingMember =
    informationRequests?.filter(
      (ir) =>
        ir.status === INFORMATION_REQUEST_STATUS.AWAITING_MEMBER ||
        ir.status === INFORMATION_REQUEST_STATUS.NOTIFY_ADMIN,
    ) || [];
  const awaitingAdmin =
    informationRequests?.filter(
      (ir) =>
        ir.status !== INFORMATION_REQUEST_STATUS.AWAITING_MEMBER &&
        ir.status !== INFORMATION_REQUEST_STATUS.NOTIFY_ADMIN,
    ) || [];

  useEffect(() => {
    if (awaitingMember.length === 0) {
      navigate("../../success");
    }

    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (hasUpdates) {
        e.preventDefault();
        return "";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [awaitingMember.length, hasUpdates, navigate]);

  return (
    <div className="flex h-full w-full flex-col items-center justify-center">
      <HelpBubble
        title={formatMessage(messages.notificationTitle)}
        message={
          <>
            <FormattedMessage
              {...messages.linkTextAR}
              values={{ br: <br /> }}
            />
            <a
              className="text-[#9D81C5] underline decoration-1 "
              href={window.env.verifyLocalAccount}
              target="_blank"
            >
              <FormattedMessage {...messages.link} />
            </a>
            <FormattedMessage
              {...messages.secLinkText}
              values={{ br: <br /> }}
            />
            <a
              className="text-[#9D81C5] underline decoration-1"
              href={window.env.verifyForeignAccount}
              target="_blank"
            >
              <FormattedMessage {...messages.link} />
            </a>
            <FormattedMessage
              {...messages.thirstLinkText}
              values={{ br: <br /> }}
            />
          </>
        }
      />
      <div className="flex h-full w-full flex-col p-12">
        <div className="relative mb-8 flex items-center justify-between border-b border-[--milkylb-color-secondary-200] pb-8">
          <h1 className="font-['Tomato_Grotesk'] text-4xl font-bold text-[--milkylb-color-neutral-800]">
            <FormattedMessage {...messages.title} />
          </h1>
          <div className="flex flex-row gap-5">
            <LogOutButton className="w-48" />
            <Button className="mr-4" onClick={onSubmit}>
              <UploadIcon className="mr-2" />
              <FormattedMessage {...messages.sendDocs} />
            </Button>
          </div>
        </div>
        <div className="flex flex-col gap-12">
          {awaitingMember.length > 0 && (
            <div className="flex flex-col gap-6">
              <div className="flex w-full flex-row items-center justify-between">
                <label className="text-2xl font-bold text-[--milkylb-color-neutral-600]">
                  <FormattedMessage {...messages.awaitingMember} />
                </label>
                {hasUpdates && (
                  <div>
                    <AlertCard
                      className="items-center"
                      text={
                        <h3 className="text-left font-['Source_Sans_Pro'] font-semibold">
                          <FormattedMessage
                            {...messages.alertText}
                            values={{ br: <br /> }}
                          />
                        </h3>
                      }
                    />
                  </div>
                )}
              </div>
              <div className="flex gap-6">
                {awaitingMember.map((informationRequest) => (
                  <InformationRequestCard
                    informationRequest={informationRequest}
                    onClick={() =>
                      navigate("./upload/" + informationRequest.id)
                    }
                  />
                ))}
              </div>
            </div>
          )}
          {awaitingAdmin.length > 0 && (
            <div className="flex flex-col gap-6">
              <label className="text-2xl font-bold text-[--milkylb-color-neutral-600]">
                <FormattedMessage {...messages.awaitingAdmin} />
              </label>
              <div className="flex gap-6">
                {awaitingAdmin.map((informationRequest) => (
                  <InformationRequestCard
                    informationRequest={informationRequest}
                    className="cursor-auto opacity-50"
                  />
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
